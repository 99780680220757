<script setup lang="ts"></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="65.912"
    height="65.908"
    viewBox="0 0 65.912 65.908"
  >
    <g
      id="Raggruppa_8527"
      data-name="Raggruppa 8527"
      transform="translate(-1197.22 -1340.955)"
    >
      <path
        id="Tracciato_7942"
        data-name="Tracciato 7942"
        d="M1197.22,1371.847c.17-1.249.313-2.5.515-3.747a33.064,33.064,0,0,1,23.825-26.016,33.438,33.438,0,0,1,12.433-.9c.887.107,1.4.672,1.322,1.417a1.337,1.337,0,0,1-1.648,1.127,30.364,30.364,0,0,0-24,52.558,29.476,29.476,0,0,0,16.821,7.762,30.258,30.258,0,0,0,33.078-22.538,29.636,29.636,0,0,0-4.533-25.064,1.371,1.371,0,0,0-.511-.472c0,.394.014.788,0,1.181a1.28,1.28,0,0,1-1.248,1.294,1.253,1.253,0,0,1-1.292-1.184q-.063-2.541,0-5.083a1.211,1.211,0,0,1,1.284-1.183c1.63-.018,3.261-.021,4.891,0a1.232,1.232,0,0,1,1.256,1.274,1.265,1.265,0,0,1-1.256,1.282c-.32.021-.643.006-.965.007h-1.064c.331.453.6.8.849,1.164a32.56,32.56,0,0,1,6,22.427,32.917,32.917,0,0,1-27.116,29.212c-1.2.2-2.413.335-3.62.5h-4.12c-.143-.035-.285-.082-.431-.1-1.5-.22-3.022-.365-4.51-.663a32.961,32.961,0,0,1-25.441-26.384c-.2-1.244-.345-2.5-.515-3.748Z"
        fill="#363636"
      />
      <path
        id="Tracciato_7943"
        data-name="Tracciato 7943"
        d="M1270.525,1392.1a22.643,22.643,0,0,0-11.149,3.157,6.436,6.436,0,0,1-.73.4,1.252,1.252,0,0,1-1.607-.54,1.278,1.278,0,0,1,.311-1.625,3.612,3.612,0,0,1,.43-.282,24.51,24.51,0,1,1-8.178,8.14,1.44,1.44,0,0,1,1.319-.867,1.292,1.292,0,0,1,1.013,1.992c-.642,1.257-1.365,2.485-1.875,3.8a21.982,21.982,0,1,0,24.171-13.787C1273.008,1392.281,1271.76,1392.229,1270.525,1392.1Z"
        transform="translate(-40.375 -40.304)"
        fill="#363636"
      />
      <path
        id="Tracciato_7944"
        data-name="Tracciato 7944"
        d="M1347.2,1474.024a4.394,4.394,0,0,1-4.127-5.93.826.826,0,0,0-.222-1.05c-1.3-1.251-2.56-2.536-3.831-3.813a1.257,1.257,0,0,1-.289-1.582,1.141,1.141,0,0,1,1.343-.642,2.136,2.136,0,0,1,.906.558q1.867,1.818,3.687,3.684a.762.762,0,0,0,.991.235,3.982,3.982,0,0,1,3.134,0,.73.73,0,0,0,.938-.208q3.987-4.021,8-8.014a4.252,4.252,0,0,1,.573-.514,1.267,1.267,0,0,1,1.776,1.733,4.2,4.2,0,0,1-.55.626q-3.979,3.984-7.97,7.956a.786.786,0,0,0-.224.992A4.393,4.393,0,0,1,1347.2,1474.024Zm.041-2.573a1.842,1.842,0,1,0-1.868-1.8A1.831,1.831,0,0,0,1347.243,1471.451Z"
        transform="translate(-117.038 -95.7)"
        fill="#363636"
      />
      <path
        id="Tracciato_7945"
        data-name="Tracciato 7945"
        d="M1480.738,1527.652c-.278,0-.556.013-.833,0a1.273,1.273,0,0,1-.1-2.538,11.132,11.132,0,0,1,1.855,0,1.273,1.273,0,0,1-.087,2.539C1481.295,1527.666,1481.016,1527.652,1480.738,1527.652Z"
        transform="translate(-233.04 -152.452)"
        fill="#363636"
      />
      <path
        id="Tracciato_7946"
        data-name="Tracciato 7946"
        d="M1383.822,1624.461c0,.278.023.557,0,.832a1.271,1.271,0,0,1-1.321,1.209,1.243,1.243,0,0,1-1.234-1.284c-.018-.512-.017-1.026,0-1.538a1.26,1.26,0,0,1,1.215-1.31,1.289,1.289,0,0,1,1.344,1.258c.022.276,0,.555,0,.833Z"
        transform="translate(-152.379 -233.015)"
        fill="#363636"
      />
      <path
        id="Tracciato_7947"
        data-name="Tracciato 7947"
        d="M1383.951,1420.828c0,.256.014.514,0,.769a1.256,1.256,0,0,1-1.166,1.227,1.238,1.238,0,0,1-1.361-1.084,9.661,9.661,0,0,1,0-1.981,1.22,1.22,0,0,1,1.373-1.061,1.242,1.242,0,0,1,1.153,1.233C1383.966,1420.229,1383.951,1420.529,1383.951,1420.828Z"
        transform="translate(-152.48 -64.369)"
        fill="#363636"
      />
      <path
        id="Tracciato_7948"
        data-name="Tracciato 7948"
        d="M1277.083,1525c.256,0,.513-.01.769,0a1.261,1.261,0,0,1,1.27,1.189,1.243,1.243,0,0,1-1.106,1.344,10.308,10.308,0,0,1-1.918,0,1.231,1.231,0,0,1-1.105-1.339,1.245,1.245,0,0,1,1.258-1.195C1276.527,1524.989,1276.8,1525,1277.083,1525Z"
        transform="translate(-64.395 -152.389)"
        fill="#363636"
      />
      <path
        id="Tracciato_7949"
        data-name="Tracciato 7949"
        d="M1435.734,1349.668a1.282,1.282,0,0,1-1.269,1.273,1.324,1.324,0,0,1-1.29-1.269,1.3,1.3,0,0,1,1.318-1.293A1.255,1.255,0,0,1,1435.734,1349.668Z"
        transform="translate(-195.375 -6.147)"
        fill="#363636"
      />
      <path
        id="Tracciato_7950"
        data-name="Tracciato 7950"
        d="M1288.188,1430.613a1.274,1.274,0,0,1-1.256,1.282,1.3,1.3,0,0,1-1.307-1.3,1.316,1.316,0,0,1,1.3-1.258A1.293,1.293,0,0,1,1288.188,1430.613Z"
        transform="translate(-73.201 -73.179)"
        fill="#363636"
      />
    </g>
  </svg>
</template>

<style scoped></style>
